<template>
    <div v-if="Object.keys(labels).length" class="space-courses client-content-padding">
        <div class="row">
            <div v-for="(course, index) in data.courses" :key="`space-course-${index}`" class="col-12 col-sm-6">
                <SpaceCourse
                    :title="course.title"
                    :description="course.description"
                    :thumbnail="course.thumbnail"
                    :tags="course.tags"
                    :button-label="labels.start_course"
                    :button-action="startCourse(course._id)"
                    :a-tag="true"
                    :a-link="`/bundle/${course._id}`"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SpaceBundles',
    props: {
        labels: {
            type: Object,
            default: () => {
                return {}
            }
        },
        data: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    components: {
        SpaceCourse: () => import('../components/SpaceCourse.vue')
    },
    data() {
        return {}
    },
    methods: {
        startCourse(id) {
            console.log(`Start course ${id}`);
        }
    }
}
</script>

<style scoped lang="scss">
    @import '../styles/spaces_common.scss';

    .space {
        &-courses {
            margin-top: -30px;
        }

        &-course {
            margin-top: 30px;
        }
    }
</style>